// Modal CSS
.modal-backdrop.show {
  opacity: 0.2;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #444;
}

.loginPopup {
  margin: 15px;
  padding-right: 17px;
}

// Button CSS
.submit {
  width: 100%;
  padding: 6px 12px;
  margin-bottom: 0;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border-radius: 4px;

  background-color: #023850;
  border-color: #023850;

  color: #fff !important;
}

.cancel {
  width: 100%;
  padding: 6px 12px;
  margin-bottom: 0;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border-radius: 4px;

  background-color: #444444;
  border-color: #444444;

  color: #fff !important;
}

.custom-submit {
  background: rgba(0, 119, 128, 1) !important;
  color: white !important;
  width: 100%;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 400;
  font-family: "Lato", sans-serif !important;
}
.custom-submit_disable {
  background: rgba(0, 119, 128, 0.5) !important;
  color: white !important;
  width: 100%;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 400;
  font-family: "Lato", sans-serif !important;
}
.fabbutton_disable {
  // background-image: -webkit-linear-gradient(
  //   180deg,
  //   rgb(31, 142, 192) 0%,
  //   rgb(103, 209, 230) 100%
  // ) !important;
  background: rgba(0, 119, 128, 0.5) !important;
}
.custom-cancel {
  background: #6c757d !important;
  color: white !important;
  width: 100%;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 400;
  font-family: "Lato", sans-serif !important;
}

.custom-fab {
  background: #1f8ebf !important;
  color: white !important;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 400;
  font-family: "Lato", sans-serif !important;
}

.custom-reject {
  background: #dc3545 !important;
  color: white !important;
  width: 100%;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 400;
  font-family: "Lato", sans-serif !important;
}
.custom-submit2 {
  // background:#1F8EBF !important;
  background: #007780 !important;
  color: white !important;
}
.custom-cancel2 {
  background: #6c757d !important;
  color: white !important;
}
.revokePullBack {
  background: #dc3545 !important;
  color: white !important;
}
.deleteButton {
  color: #007780 !important;
}
.xcelbutton {
  //   background: url(/images/excel_icon.svg) no-repeat 0 0;
  color: white !important;
  // width: 100%;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 400;
  font-family: "Lato", sans-serif !important;
  height: 32px !important;
  min-width: 40px !important;
  border-radius: 100% !important;
}
#xcelbtn .xcelbtn {
  width: 31px;
  height: 16px;
}
.custom-link {
  background: white !important;
  color: rgb(31, 142, 192) !important;
  width: 100%;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 400;
  box-shadow: none;
  font-family: "Lato", sans-serif !important;
  text-align: left;
}
#linkWeb .MuiButton-contained {
  color: rgba(0, 0, 0, 0.87);
  box-shadow: none;
  background-color: white;
  padding-left: 0px;
  color: #484d53 !important;
  font-size: 16px;
  padding-bottom: 0px;
  font-weight: 300 !important;
}
#linkWeb .MuiButton-label {
  justify-content: flex-start;
}

.iconMargin {
  margin-right: 10px;
}
